import React from "react";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";

import { render } from "react-dom";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function QuoteSection(props) {
    const {
        quote,
        image,
    } = props;
    
    return (
        <>
            <section 
                className="interior-hero" 
                style={{
                    display: "block",
                    position: "relative",
                }}
            >
                <div className="trustsection__bgimg" style={{backgroundColor: "grey"}}>
                    <PreviewCompatibleImage imageInfo={image.img}/>
                </div>

                <div className="wb-hero__content"  style={{padding: "10% 5%"}}>
                    <div className="container">
                        <div className="section">
                            <div 
                                className="h1"
                                style={{
                                    color: "white",
                                    textAlign: "center",
                                }}
                            >
                                <ReactMarkdown children={quote} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}