import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

import InteriorHero from "../components/interior-components/InteriorHero";
import GenericCopy from "../components/interior-components/GenericCopy";
import BlueSection from "../components/interior-components/BlueSection";
import QuoteSection from "../components/interior-components/QuoteSection";
import LowerSection from "../components/interior-components/LowerSection";
import { render } from "react-dom";

// eslint-disable-next-line
export const ServicePostTemplate = ({
  contentComponent,
  serv_heroHeadline,
  serv_showTitle,
  serv_thirdLevel,
  serv_title,
  serv_heroImage,
  serv_romance,
  serv_blue,
  serv_copy1,
  serv_copy2,
  serv_quote,
  serv_lowerSection,
  serv_slug,
}) => {
  const PostContent = contentComponent || Content;

  /**
   * TEMPLATE MARKUP START
   */
  return (
    <div className="main-page-content">
      {/* Required Blocks */}
      <InteriorHero
        image={serv_heroImage}
        title={serv_title}
        heroHeadline={serv_heroHeadline}
        showTitle={serv_showTitle}
        thirdLevel={serv_thirdLevel}
      />
      <GenericCopy
        copy={serv_romance.serv_romance_copy}
        image={serv_romance.image}
      />
      <BlueSection bluesection={serv_blue} />

      {/* Optional Blocks */}
      {serv_copy1.copy && (
        <GenericCopy copy={serv_copy1.copy} image={serv_copy1.image} />
      )}
      {serv_copy2.copy && (
        <GenericCopy copy={serv_copy2.copy} image={serv_copy2.image} />
      )}
      {serv_quote.copy && (
        <QuoteSection image={serv_quote.image} quote={serv_quote.copy} />
      )}
      {serv_lowerSection.copy && (
        <LowerSection
          image={serv_lowerSection.image}
          copy={serv_lowerSection.copy}
          col1={serv_lowerSection.col1}
          col2={serv_lowerSection.col2}
          markdown={serv_lowerSection.markdown}
        />
      )}
      {serv_slug?.includes("storm-damage-roof-repair") ? (
        <>
          <img
            height="1"
            width="1"
            alt=""
            src="https://insight.adsrvr.org/track/pxl/?adv=p9p2769&ct=0:65jlawk&fmt=3"
          />
          <img
            height="1"
            width="1"
            alt=""
            src="https://insight.adsrvr.org/track/pxl/?adv=p9p2769&ct=0:ssqyv4h&fmt=3"
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

ServicePostTemplate.propTypes = {
  serv_heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  serv_title: PropTypes.string,
  serv_showTitle: PropTypes.bool,
  serv_thirdLevel: PropTypes.bool,
  serv_heroHeadline: PropTypes.string,
  serv_romance: PropTypes.object,
  serv_blue: PropTypes.object,
  serv_copy1: PropTypes.object,
  serv_copy2: PropTypes.object,
  serv_quote: PropTypes.object,
  serv_lowerSection: PropTypes.object,
  serv_slug: PropTypes.string,
};

const ServicePost = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout
      helmet={
        <Helmet>
          <title>{`${post?.frontmatter?.serv_title}`}</title>
          <meta
            name="description"
            content={`${post?.frontmatter?.description}`}
          />
          <meta property="og:title" content={post?.frontmatter?.serv_title} />
        </Helmet>
      }
    >
      <ServicePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Services">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        serv_title={post.frontmatter.serv_title}
        serv_heroHeadline={post.frontmatter.serv_heroHeadline}
        serv_showTitle={post.frontmatter.serv_showTitle}
        serv_thirdLevel={post.frontmatter.serv_thirdLevel}
        serv_romance={post.frontmatter.serv_romance}
        serv_blue={post.frontmatter.serv_blue}
        serv_copy1={post.frontmatter.serv_copy1}
        serv_copy2={post.frontmatter.serv_copy2}
        serv_quote={post.frontmatter.serv_quote}
        serv_lowerSection={post.frontmatter.serv_lowerSection}
        serv_heroImage={post.frontmatter.serv_heroImage}
        serv_slug={post?.fields?.slug}
      />
    </Layout>
  );
};

ServicePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ServicePost;

export const pageQuery = graphql`
  query ServicesQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        serv_title
        description
        serv_heroHeadline
        serv_showTitle
        serv_thirdLevel
        serv_heroImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        serv_romance {
          image {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          serv_romance_copy
        }
        serv_blue {
          heading
          copy
          markdown
          images {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
        }
        serv_copy1 {
          image {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          copy
        }
        serv_copy2 {
          image {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          copy
        }
        serv_quote {
          image {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          copy
        }
        serv_lowerSection {
          image {
            img {
              childImageSharp {
                gatsbyImageData(width: 800, quality: 85, layout: CONSTRAINED)
              }
            }
            alt
          }
          copy
          col1
          col2
          markdown
        }
      }
    }
  }
`;
