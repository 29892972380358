import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function BlueSection(props) {
  const { bluesection } = props;

  return (
    <>
      {bluesection?.images && bluesection?.images.length > 0 && (
        <div className="trustsection">
          <section className="section pt-0 pb-0">
            <div className="container">
              <div className="section pt-0 pb-0">
                <div className="columns is-gapless">
                  <div className="column is-half p-0">
                    <div className="trustsection__imgs __bluecopy__blueimgs">
                      {bluesection.images.map((item, indx) => (
                        <div
                          key={indx}
                          className={
                            "trustsection__img trustsection__sideimg images_" +
                            indx
                          }
                          style={{
                            top: (100 / bluesection.images.length) * indx + "%",
                            height: 100 / bluesection.images.length + "%",
                          }}
                        >
                          <PreviewCompatibleImage
                            key={indx}
                            _img
                            imageInfo={item.img}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="trustsection__copy px-6">
                      <h1 className="trustsection__title">
                        {bluesection.heading}
                      </h1>
                      <div className="not-trustsection__copy_main trustsection__bluecopy">
                        <ReactMarkdown
                          children={bluesection.copy}
                          rehypePlugins={[rehypeRaw]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
