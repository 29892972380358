import React from "react";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";

import { render } from "react-dom";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function LowerSection(props) {
    const {
        copy,
        col1,
        col2,
        markdown,
        image,
    } = props;
    
    return (
        <>
            <div className="testimonialsection__outer section pt-0 pb-0">
                <div className="container">
                    <div className="sectiona">
                        <div className="testimonialsection__wrapper">
                            <div className="testimonialsection columns is-gapless">
                                <div className="column is-half">
                                    <div className="testimonialsection__quotes">
                                        <div className="testimonialsection__quotes_bg this--lowersection-version">
                                            <PreviewCompatibleImage imageInfo={image.img} />
                                        </div>
                                        {/* <div className="testimonialsection__quotes_wrapper">
                                            <div className="testimonialsection__quote is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        blah
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - blah
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="testimonialsection__quote testimonialsection__quote--offset is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        {testimonialsection.testimonial2.copy}
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - {testimonialsection.testimonial2.personname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="testimonialsection__quote is-flex is-align-items-center">
                                                <div className="testimonialsection__quote_inner">
                                                    <div className="testimonialsection__quote_copy">
                                                        {testimonialsection.testimonial3.copy}
                                                    </div>
                                                    <div className="testimonialsection__quote_person">
                                                        - {testimonialsection.testimonial3.personname}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="testimonialsection__copy px-6">
                                        <div className="testimonialsection__copy_inner testimonialsection__lowersection">
                                            <ReactMarkdown children={copy} rehypePlugins={[rehypeRaw]} />

                                            <div className="columns is-gapless">
                                                <div className="column is-half">
                                                    <ReactMarkdown children={col1} rehypePlugins={[rehypeRaw]} />
                                                </div>
                                                <div className="column is-half">
                                                    <ReactMarkdown children={col2} rehypePlugins={[rehypeRaw]} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <section className="section pt-0 pb-0">
                <div className="container">
                    <div className="section pt-0 pb-0">
                        <div className="columns is-gapless">

                            <div className="column is-half romance-aside p-0">
                                <div className="romance-aside__wrapper">
                                    <PreviewCompatibleImage imageInfo={image.img}/>
                                </div>
                            </div>

                            <div className="column is-half pl-5 pr-5 pt-3">
                                <div className="content p-5">
                                    <div className="tile">
                                        <div dangerouslySetInnerHTML={{ __html: copy }} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section> */}
        </>
    );

}